// SnakeGame.js
import React, { useState, useEffect, useCallback } from "react";
import {
  DiJavascript1,
  DiReact,
  DiPython,
  DiGit,
  DiAws,
} from "react-icons/di";
import { SiFirebase, SiMysql, SiGnubash } from "react-icons/si";
import { TbBrandCpp, TbBrandCSharp } from "react-icons/tb";
import "./SnakeGame.css";
import { database } from "../../firebase"; // Import Firebase database
import { ref, onValue, push } from "firebase/database"; // Import Firebase functions
import { Link } from 'react-router-dom';

const techIcons = [
  <DiJavascript1 />,
  <DiReact />,
  <DiPython />,
  <DiGit />,
  <DiAws />,
  <SiFirebase />,
  <SiMysql />,
  <SiGnubash />,
  <TbBrandCpp />,
  <TbBrandCSharp />,
];

const firebasePath = "snakegame/highscores"; // Define Firebase path for high scores

const GRID_SIZE = 15;

const SnakeGame = () => {
  const initialSnake = [
    { x: Math.floor(GRID_SIZE / 2), y: Math.floor(GRID_SIZE / 2) },
    { x: Math.floor(GRID_SIZE / 2) - 1, y: Math.floor(GRID_SIZE / 2) },
  ];

  const [snake, setSnake] = useState(initialSnake);
  const [direction, setDirection] = useState({ x: 1, y: 0 });
  const [nextDirection, setNextDirection] = useState({ x: 1, y: 0 });
  const [food, setFood] = useState(getRandomFoodPosition(initialSnake));
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [isGameOver, setIsGameOver] = useState(false);
  const [currentIcon, setCurrentIcon] = useState(getRandomTechIcon());

  // Helper functions
  function getRandomFoodPosition(snakePositions) {
    let position;
    do {
      position = {
        x: Math.floor(Math.random() * GRID_SIZE),
        y: Math.floor(Math.random() * GRID_SIZE),
      };
    } while (
      snakePositions.some(
        (segment) => segment.x === position.x && segment.y === position.y
      )
    );
    return position;
  }

  function getRandomTechIcon() {
    return techIcons[Math.floor(Math.random() * techIcons.length)];
  }

  const handleKeyDown = (e) => {
    let newDirection = direction;
    switch (e.key) {
      case "ArrowUp":
      case "w":
        e.preventDefault();
        if (direction.y !== 1) newDirection = { x: 0, y: 1 };
        break;
      case "ArrowDown":
      case "s":
        e.preventDefault();
        if (direction.y !== -1) newDirection = { x: 0, y: -1 };
        break;
      case "ArrowLeft":
      case "a":
        e.preventDefault();
        if (direction.x !== 1) newDirection = { x: -1, y: 0 };
        break;
      case "ArrowRight":
      case "d":
        e.preventDefault();
        if (direction.x !== -1) newDirection = { x: 1, y: 0 };
        break;
      default:
        break;
    }
    setNextDirection(newDirection);
  };

  // On-screen controls handlers
  const moveUp = () => {
    if (direction.y !== 1) setNextDirection({ x: 0, y: 1 });
  };
  const moveDown = () => {
    if (direction.y !== -1) setNextDirection({ x: 0, y: -1 });
  };
  const moveLeft = () => {
    if (direction.x !== 1) setNextDirection({ x: -1, y: 0 });
  };
  const moveRight = () => {
    if (direction.x !== -1) setNextDirection({ x: 1, y: 0 });
  };

  const moveSnake = useCallback(() => {
    const newHead = {
      x: snake[0].x + nextDirection.x,
      y: snake[0].y + nextDirection.y,
    };
    //console.log(snake[0].x, snake[0].y);
    // Check collision with walls
    if (
      newHead.x < 0 ||
      newHead.x >= GRID_SIZE ||
      newHead.y < 0 ||
      newHead.y >= GRID_SIZE
    ) {
      setIsGameOver(true);
      if (score > highScore) {
        setHighScore(score);
        saveHighScore();
      }
      return;
    }

    // Determine if the snake will grow
    const willGrow = newHead.x === food.x && newHead.y === food.y;

    // Exclude tail from collision check if the snake is not growing
    const snakeBody = willGrow ? snake : snake.slice(0, -1);

    // Check collision with self
    if (
      snakeBody.some(
        (segment) => segment.x === newHead.x && segment.y === newHead.y
      )
    ) {
      setIsGameOver(true);
      if (score > highScore) {
        setHighScore(score);
        saveHighScore();
      }
      return;
    }

    const newSnake = [newHead, ...snake];

    if (willGrow) {
      setFood(getRandomFoodPosition(newSnake));
      setScore(score + 1);
      setCurrentIcon(getRandomTechIcon());
    } else {
      newSnake.pop();
    }

    setSnake(newSnake);
    setDirection(nextDirection);
  }, [snake, nextDirection, food, score, highScore, direction]);

  const saveHighScore = async () => {
    const username = prompt(
      "New high score! Enter your name (8 characters max):",
      "Guest"
    );
    if (username && username.length <= 8) {
      const newScore = { name: username, score };
      await push(ref(database, firebasePath), newScore);
    }
  };

  const fetchLeaderboard = () => {
    const highScoresRef = ref(database, firebasePath);
    onValue(highScoresRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const sortedScores = Object.values(data)
          .sort((a, b) => b.score - a.score)
          .slice(0, 5); // Top 5 scores
        setLeaderboard(sortedScores);
      }
    });
  };

  const restartGame = () => {
    if (score > highScore) {
      setHighScore(score);
      saveHighScore();
    }
    setSnake(initialSnake);
    setDirection({ x: 1, y: 0 });
    setNextDirection({ x: 1, y: 0 });
    setFood(getRandomFoodPosition(initialSnake));
    setScore(0);
    setIsGameOver(false);
    setCurrentIcon(getRandomTechIcon());
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (isGameOver) return;

    const interval = setInterval(() => {
      moveSnake();
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [moveSnake, isGameOver]);

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  return (
    <div className="snake-game-container">
      <div className="game-area">
        <h2>Snake Game</h2>
        <h6>Catch the languages and technologies I know</h6>
        <div className="score-board">
          <p>Score: {score}</p>
          <p>High Score: {highScore}</p>
          {isGameOver && <p className="game-over">Game Over!</p>}
        </div>
        <div className="game-content">
          {Array.from({ length: GRID_SIZE }).map((_, i) => {
            const y = GRID_SIZE - 1 - i; // Reverse y to match coordinate system
            return (
              <div key={y} className="grid-row">
                {Array.from({ length: GRID_SIZE }).map((_, x) => {
                  let className = "";
                  let content = null;

                  if (
                    snake.some(
                      (segment) => segment.x === x && segment.y === y
                    )
                  ) {
                    className = "snake";
                  } else if (food.x === x && food.y === y) {
                    className = "food";
                    content = currentIcon;
                  }

                  return (
                    <div key={`${x}-${y}`} className={`cell ${className}`}>
                      {content}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
        <div className="controls">
          <p>
            Controls: Use Arrow keys, WASD, or the on-screen buttons to move
          </p>
          <button onClick={restartGame} className="restart-btn">
            Restart
          </button>
          {/* On-screen controls */}
          <div className="onscreen-controls">
            <button className="control-btn up" onClick={moveUp}>
              Up
            </button>
            <div className="left-right-controls">
              <button className="control-btn left" onClick={moveLeft}>
                Left
              </button>
              <button className="control-btn down" onClick={moveDown}>
                Down
              </button>
              <button className="control-btn right" onClick={moveRight}>
                Right
              </button>
            </div>
          </div>
        </div>
      {/* Leaderboard */}
      <div className="leaderboard">
        <h3>Leaderboard</h3>
        <ul>
          {leaderboard.map((entry, index) => (
            <li key={index}>
              {entry.name} - {entry.score}
            </li>
          ))}
        </ul>
      </div>
      <p>
        Return to <Link to="/">Home</Link>
      </p>
    </div>
  );
};

export default SnakeGame;
