import React, { useState, useEffect, useRef } from "react";
import {
  DiJavascript1,
  DiReact,
  DiPython,
  DiGit,
  DiAws,
} from "react-icons/di";
import {
  SiFirebase,
  SiMysql,
  SiGnubash,
} from "react-icons/si";
import { TbBrandCpp, TbBrandCSharp } from "react-icons/tb";

import "./FruitNinjaTechnologiesGame.css"; // You can create custom styles here
import { database } from "../../firebase"; // Firebase database import
import { ref, onValue, push } from "firebase/database";
import { Link } from 'react-router-dom';

const techIcons = [
  <DiJavascript1 />,
  <DiReact />,
  <DiPython />,
  <DiGit />,
  <DiAws />,
  <SiFirebase />,
  <SiMysql />,
  <SiGnubash />,
  <TbBrandCpp />,
  <TbBrandCSharp />,
];

const firebasePath = "fruitninja/highscores";


const getRandomPosition = () => ({
  x: Math.random() * 90 + 5, // Random horizontal position (5-95%)
  y: 100, // Start from bottom of the screen
});

const getRandomSpeed = () => Math.random() * 3 + 2; // Speed of the flying technologies

const FruitNinjaTechnologiesGame = () => {
  const [technologies, setTechnologies] = useState([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [highScore, setHighScore] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const gameAreaRef = useRef();

  // Fetch leaderboard from Firebase on component mount
  useEffect(() => {
    const highScoresRef = ref(database, firebasePath);
    const unsubscribe = onValue(highScoresRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const sortedScores = Object.values(data)
          .sort((a, b) => b.score - a.score)
          .slice(0, 5); // Top 5 scores
        setLeaderboard(sortedScores);
      }
    });
    return () => unsubscribe();
  }, []);

  // Add new flying technologies every 1.5 seconds
  useEffect(() => {
    if (gameOver) return;

    const interval = setInterval(() => {
      setTechnologies((prevTechs) => [
        ...prevTechs,
        {
          id: Date.now(),
          position: getRandomPosition(),
          icon: techIcons[Math.floor(Math.random() * techIcons.length)],
          speed: getRandomSpeed(),
        },
      ]);
    }, 1500);

    return () => clearInterval(interval);
  }, [gameOver]);

  // Move technologies upwards and check if they pass the top without being sliced
  useEffect(() => {
    if (gameOver) return;

    const gameLoop = setInterval(() => {
      setTechnologies((prevTechs) => {
        const updatedTechs = prevTechs.map((tech) => ({
          ...tech,
          position: { ...tech.position, y: tech.position.y - tech.speed },
        }));

        const missedTech = updatedTechs.some((tech) => tech.position.y <= -10); // Off-screen

        if (missedTech) {
          setGameOver(true);
          if (score > highScore) {
            setHighScore(score);
            saveHighScore();
          }
        }

        return updatedTechs.filter((tech) => tech.position.y > -10); // Remove off-screen techs
      });
    }, 50); // Game loop runs every 50ms

    return () => clearInterval(gameLoop);
  }, [technologies, gameOver]);

  // Handle slicing (mouse or touch)
  const handleSlice = (event) => {
    const sliceX = event.clientX || event.touches?.[0]?.clientX;
    const sliceY = event.clientY || event.touches?.[0]?.clientY;

    setTechnologies((prevTechs) => {
      const newTechs = prevTechs.filter((tech) => {
        const techX = (tech.position.x / 100) * window.innerWidth;
        const techY = (tech.position.y / 100) * window.innerHeight;
        const isSliced = Math.abs(sliceX - techX) < 50 && Math.abs(sliceY - techY) < 50; // 50px radius slicing
        if (isSliced) {
          setScore((prevScore) => prevScore + 1); // Increase score when sliced
        }
        return !isSliced;
      });
      return newTechs;
    });
  };

  // Save high score to Firebase
  const saveHighScore = async () => {
    const username = prompt("New high score! Enter your name (8 characters max):", "Guest");
    if (username && username.length <= 8) {
      const newScore = { name: username, score };
      await push(ref(database, firebasePath), newScore);
    }
  };

  const restartGame = () => {
    setScore(0);
    setGameOver(false);
    setTechnologies([]);
  };

  return (
    <div
      ref={gameAreaRef}
      className="game-area"
      onMouseMove={handleSlice} // Handle slicing with mouse movement
      onTouchMove={handleSlice} // Handle slicing with touch
    >
      <h2>Fruit Ninja: Technologies Edition</h2>
      <div className="leaderboard">
        <h3>Leaderboard</h3>
        <ul>
          {leaderboard.map((entry, index) => (
            <li key={index}>
              {entry.name} - {entry.score}
            </li>
          ))}
        </ul>
      </div>
      <div className="score-board">
        <p>Score: {score}</p>
        <p>High Score: {highScore}</p>
        {gameOver && <p className="game-over">Game Over! A technology escaped unsliced!</p>}
      </div>
      <div className="technologies">
        {technologies.map((tech) => (
          <div
            key={tech.id}
            className="tech-icon"
            style={{
              left: `${tech.position.x}%`,
              top: `${tech.position.y}%`,
            }}
          >
            {tech.icon}
          </div>
        ))}
      </div>
      <div className="controls">
        <button onClick={restartGame}>Restart</button>
      </div>
      <p>Return to <Link to="/">Home</Link></p>
    </div>
  );
};

export default FruitNinjaTechnologiesGame;
