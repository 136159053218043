import React, { useState } from 'react';
import { database } from '../../firebase'; // Importing Firebase database
import { ref, push } from 'firebase/database'; // Firebase Realtime Database methods
import './ContactUs.css'; // Import CSS styles

const ContactUs = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [sendingStatus, setSendingStatus] = useState('');
  const [isSent, setIsSent] = useState(false); // Track if message was sent

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reference to the contact_messages node in Realtime Database
    const messagesRef = ref(database, 'contact_messages'); // Reference to the base node

    // Store form data in Realtime Database using push
    try {
      await push(messagesRef, {
        name: formData.name,
        email: formData.email,
        message: formData.message,
        timestamp: new Date().toISOString(), // Store original timestamp without modification
      });

      setSendingStatus('Message sent successfully!');
      setIsSent(true); // Set message sent status to true
      setFormData({ name: '', email: '', message: '' }); // Reset form fields
    } catch (error) {
      console.error("Error saving message:", error);
      setSendingStatus('Error sending message. Please try again.');
      setIsSent(false); // Reset message sent status
    }
  };

  return (
    <div className='contact-us-container'>
      <h1>Contact Me</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div>
          <label>Message:</label>
          <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>
        </div>
        <button type="submit">Send Message</button>
      </form>
      {sendingStatus && (
        <p className={isSent ? 'swoosh' : ''}>{sendingStatus}</p>
      )} {/* Display sending status with swoosh effect */}
    </div>
  );
};

export default ContactUs;