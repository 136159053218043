import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import './Footer.css';

function Footer() {
  const [stockData, setStockData] = useState({});
  const [error, setError] = useState(null); // Error state
  const symbols = [
    "AAPL", "GOOGL", "MSFT", "AMZN", "TSLA",
    "JPM", "UBS", "KO", "AXP", "MS",
    "LYG", "DIS", "BP", "QCOM", "BCS",
    "CSR", "CMCSK", "ERTS", "XLNX", "C"
  ];
  
  // Array of quant quotes
  const quantQuotes = [
    "Risk comes from not knowing what you're doing. - Warren Buffett",
    "The four most dangerous words in investing are: 'This time it's different.' - Warren Buffett",
    "The market is a device for transferring money from the impatient to the patient.",
    "Time is your friend; impulse is your enemy. - John Bogle",
    "I'm not made of airports! - Mr. Burns",
    "We can't afford to shop at any store that has a philosophy - Marge Simpson",
    "Price is what you pay. Value is what you get. - Warren Buffet",
    "Math is the only place where people buy 60 watermelons and nobody wonders why.",
    "“In God we trust. All others must bring data.” – W. Edwards Deming",
    "“Who needs a crystal ball when you have stochastic models?”",
    "“I run on coffee, code, and confidence intervals.”",
    "“My favorite machine-learning model is the one that works (at least 60% of the time).”",
    "“I don’t always hedge my bets, but when I do, I prefer to model them first.”",
    "“If I had a dollar for every time I debugged code, I’d already have my own hedge fund.”",
    "“I don’t make mistakes, I just develop interesting outliers.”",
    "“My relationship with volatility is complicated, but at least it’s not random.”",
    "“There are 10 kinds of people in the world: those who understand binary and those who don’t.”",
    "“I’m not overthinking, I’m just calculating the risk-reward ratio.”",


  ];

  const ALPHAVANTAGE_API_KEY = "7FIXUA4GVVFZ9DHJ";

  useEffect(() => {
    const fetchRandomStock = async () => {
      try {
        const randomSymbol = symbols[Math.floor(Math.random() * symbols.length)];
        const response = await axios.get(`https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${randomSymbol}&apikey=${ALPHAVANTAGE_API_KEY}`);
        
        //console.log(response.data);  // Log the full API response
        const stockQuote = response.data["Global Quote"];

        if (stockQuote) {
          const price = stockQuote["05. price"];
          const changePercent = stockQuote["10. change percent"];
          const trendArrow = changePercent.startsWith("-") ? "⬇️" : "⬆️";
    
          setStockData({ symbol: randomSymbol, price, trendArrow });
          setError(null); // Clear error state if successful
        } else {
          //console.error("API Response Error: No stock data available");
          setError("Stock quote data is unavailable.");
        }
      } catch (error) {
        //console.error("Error fetching stock data:", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          //console.error("API Response Status:", error.response.status);
          //console.error("API Response Data:", error.response.data);
          setError("Failed to fetch stock data. Please check your API key or try again later.");
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the API. Please check your internet connection.");
        } else {
          // Something happened in setting up the request that triggered an Error
          setError("Error setting up the request: " + error.message);
        }
      }
    };
    

    fetchRandomStock();
  }, []);

  const year = new Date().getFullYear();
  const randomQuote = quantQuotes[Math.floor(Math.random() * quantQuotes.length)];

  return (
    <Container fluid className="footer">
      <Row>
        
        <Col md="4" className="footer-stock-ticker" style={{ display: 'flex', alignItems: 'center' }}>
          {error ? (
            <p style={{ color: "red" }}> </p>
          ) : stockData.symbol ? (
            <h4 style={{ color: "#03dac6" }}>
              {stockData.symbol}: ${stockData.price} {stockData.trendArrow}
            </h4>
          ) : (
            <p>Loading stock price...</p>
          )}
        </Col>
        <Col md="4" className="footer-social-media" style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginLeft: '10px', color: "#03dac6" }}>"{randomQuote}"</p>
        </Col>
      </Row>
      <Col md="4" className="footer-copywright">
          <h3>© {year} Jide Oyelayo. All rights reserved.</h3>
        </Col>
    </Container>
  );
}

export default Footer;
