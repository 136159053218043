// SpaceRaiders.js
import React, { useState, useEffect, useRef } from "react";
import { database } from "../../firebase"; // Import the database instance
import { ref, onValue, push } from "firebase/database";
import {
  DiJavascript1,
  DiReact,
  DiPython,
  DiGit,
  DiAws,
} from "react-icons/di";
import { SiFirebase, SiMysql, SiGnubash } from "react-icons/si";
import { TbBrandCpp, TbBrandCSharp } from "react-icons/tb";
import { Link } from 'react-router-dom';

import "./SpaceRaiders.css";

// Define the tech icons to avoid
const techIcons = [
  <DiJavascript1 />,
  <DiReact />,
  <DiPython />,
  <DiGit />,
  <DiAws />,
  <SiFirebase />,
  <SiMysql />,
  <SiGnubash />,
  <TbBrandCpp />,
  <TbBrandCSharp />,
];

// New path in Firebase to avoid clashes
const firebasePath = "spaceraiders/highscores";

const SpaceRaiders = () => {
  const [spaceship, setSpaceship] = useState({ x: 7, y: 13 });
  const [technologies, setTechnologies] = useState(getInitialTechnologies());
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);
  const gameAreaRef = useRef();

  useEffect(() => {
    // Listen to the leaderboard updates from Firebase Realtime Database
    const highScoresRef = ref(database, firebasePath);

    const unsubscribe = onValue(highScoresRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const sortedScores = Object.values(data)
          .sort((a, b) => b.score - a.score)
          .slice(0, 5); // Get the top 5 scores
        setLeaderboard(sortedScores);
      } else {
        setLeaderboard([]);
      }
    });

    return () => unsubscribe();
  }, []);

  // Initial technology positions
  function getInitialTechnologies() {
    return Array.from({ length: 5 }, () => ({
      x: Math.floor(Math.random() * 15),
      y: Math.floor(Math.random() * 5),
      icon: getRandomTechIcon(),
    }));
  }

  function getRandomTechIcon() {
    return techIcons[Math.floor(Math.random() * techIcons.length)];
  }

  const handleKeyDown = (e) => {
    if (isGameOver) return;

    let newPos = { ...spaceship };
    switch (e.key) {
      case "ArrowUp":
      case "w":
        newPos.y = Math.max(0, spaceship.y - 1);
        break;
      case "ArrowDown":
      case "s":
        newPos.y = Math.min(14, spaceship.y + 1);
        break;
      case "ArrowLeft":
      case "a":
        newPos.x = Math.max(0, spaceship.x - 1);
        break;
      case "ArrowRight":
      case "d":
        newPos.x = Math.min(14, spaceship.x + 1);
        break;
      default:
        break;
    }
    setSpaceship(newPos);
  };

  const moveTechnologies = () => {
    const newTechnologies = technologies.map((tech) => ({
      ...tech,
      y: tech.y + 1,
    }));

    if (
      newTechnologies.some(
        (tech) => tech.y === spaceship.y && tech.x === spaceship.x
      )
    ) {
      setIsGameOver(true);
      if (score > highScore) {
        setHighScore(score);
        saveHighScore();
      }
      return;
    }

    const updatedTechnologies = newTechnologies.filter((tech) => tech.y < 15);

    while (updatedTechnologies.length < 5) {
      updatedTechnologies.push({
        x: Math.floor(Math.random() * 15),
        y: 0,
        icon: getRandomTechIcon(),
      });
    }

    setTechnologies(updatedTechnologies);
    setScore((prev) => prev + 1);
  };

  const saveHighScore = async () => {
    const username = prompt(
      "New high score! Enter your name (8 characters max):",
      "Guest"
    );
    if (username && username.length <= 8) {
      const newScore = { name: username, score };
      await push(ref(database, firebasePath), newScore);
    }
  };

  const restartGame = () => {
    if (score > highScore) {
      setHighScore(score);
      saveHighScore();
    }
    setSpaceship({ x: 7, y: 13 });
    setTechnologies(getInitialTechnologies());
    setScore(0);
    setIsGameOver(false);
  };

  useEffect(() => {
    if (isGameOver) return;

    document.addEventListener("keydown", handleKeyDown);

    const interval = setInterval(() => {
      moveTechnologies();
    }, 300);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      clearInterval(interval);
    };
  }, [technologies, spaceship, isGameOver]);

  // On-screen button controls for mobile
  const handleUp = () => {
    if (isGameOver) return;
    if (spaceship.y > 0) setSpaceship((prev) => ({ ...prev, y: prev.y - 1 }));
  };

  const handleDown = () => {
    if (isGameOver) return;
    if (spaceship.y < 14) setSpaceship((prev) => ({ ...prev, y: prev.y + 1 }));
  };

  const handleLeft = () => {
    if (isGameOver) return;
    if (spaceship.x > 0) setSpaceship((prev) => ({ ...prev, x: prev.x - 1 }));
  };

  const handleRight = () => {
    if (isGameOver) return;
    if (spaceship.x < 14) setSpaceship((prev) => ({ ...prev, x: prev.x + 1 }));
  };

  return (
    <div className="space-raiders-container">
      <div ref={gameAreaRef} className="game-area">
        <h2>Space Raiders</h2>
        <h6>Avoid the technologies!</h6>
        <div className="score-board">
          <p>Score: {score}</p>
          <p>High Score: {highScore}</p>
          {isGameOver && <p className="game-over">Game Over!</p>}
        </div>
        <div className="game-content">
          <div className="grid">
            {[...Array(15)].map((_, y) =>
              [...Array(15)].map((_, x) => {
                let className = "";
                let content = null;

                if (spaceship.x === x && spaceship.y === y) {
                  className = "spaceship";
                  content = "🚀";
                } else {
                  const tech = technologies.find(
                    (tech) => tech.x === x && tech.y === y
                  );
                  if (tech) {
                    className = "technology";
                    content = tech.icon;
                  }
                }

                return (
                  <div key={`${x}-${y}`} className={`cell ${className}`}>
                    {content}
                  </div>
                );
              })
            )}
          </div>
        </div>
      <div className="controls">
        <p>Controls: Use Arrow keys, WASD, or the on-screen buttons to move</p>
        <button onClick={restartGame} className="restart-btn">
          Restart
        </button>
      </div>
      </div>
        {/* On-screen controls for mobile */}
        <div className="onscreen-controls">
          <button className="control-btn up" onClick={handleUp}>
            Up
          </button>
          <div className="left-right-controls">
            <button className="control-btn left" onClick={handleLeft}>
              Left
            </button>
            <button className="control-btn down" onClick={handleDown}>
              Down
            </button>
            <button className="control-btn right" onClick={handleRight}>
              Right
            </button>
          </div>
        </div>
      {/* Leaderboard */}
      <div className="leaderboard">
        <h3>Leaderboard</h3>
        <ul>
          {leaderboard.map((entry, index) => (
            <li key={index}>
              {entry.name} - {entry.score}
            </li>
          ))}
        </ul>
      </div>
      <p>Return to <Link to="/">Home</Link></p>
    </div>
  );
};

export default SpaceRaiders;
