// usePageTracking.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-CEECV901PH', {
        page_path: location.pathname,
      });
    }
  }, [location]);
}
