import React, { useState } from 'react';
import { auth } from '../../firebase'; // Ensure this path is correct based on your project structure
import { sendPasswordResetEmail } from 'firebase/auth';
import './ForgotPassword.css'; // Import the CSS file
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setError(''); // Reset error message before sending
        setSuccessMessage(''); // Reset success message before sending
        setLoading(true); // Set loading state to true

        try {
            await sendPasswordResetEmail(auth, email);
            setSuccessMessage('Check your email for the password reset link.');
        } catch (error) {
            setError('Failed to send reset email. Please check your email address.');
            console.error(error.message); // Log the error for debugging
        } finally {
            setLoading(false); // Stop loading when done
        }
    };

    return (
        <div className='forgot-password-container'>
            <h1>Forgot Password</h1>
            <form onSubmit={handleResetPassword}>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className='email-input'
                />
                <button type="submit" disabled={loading} className='submit-button'>
                    {loading ? 'Sending...' : 'Send Reset Email'}
                </button>
                {error && <p className="error-message">{error}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
                <Link to="/secret">Go Back</Link>

            </form>
        </div>
    );
};

export default ForgotPassword;
