import React, { useState, useEffect } from 'react';
import { database } from '../../firebase'; // Adjust the import path as needed
import { ref, onValue, set } from 'firebase/database';
import './BlockStacker.css';
import { Col, Row } from 'react-bootstrap';

// Importing technology icons
import {
  DiJavascript1,
  DiReact,
  DiPython,
  DiGit,
  DiAws,
} from 'react-icons/di';
import { SiFirebase, SiMysql, SiGnubash } from 'react-icons/si';
import { TbBrandCpp, TbBrandCSharp } from 'react-icons/tb';
import { Link } from 'react-router-dom';

const techIcons = [
  <DiJavascript1 />,
  <DiReact />,
  <DiPython />,
  <DiGit />,
  <DiAws />,
  <SiFirebase />,
  <SiMysql />,
  <SiGnubash />,
  <TbBrandCpp />,
  <TbBrandCSharp />,
];

const firebasePath = 'blockstacker/highscore';

const BlockStacker = () => {
  const gridWidth = 10;
  const gridHeight = 20;
  const initialPosition = { x: Math.floor(gridWidth / 2) - 1, y: -1 };

  const shapes = [
    // O Shape
    [
      [1, 1],
      [1, 1],
    ],
    // I Shape
    [[1], [1], [1], [1]],
    // T Shape
    [
      [0, 1, 0],
      [1, 1, 1],
    ],
    // L Shape
    [
      [1, 0],
      [1, 0],
      [1, 1],
    ],
    // J Shape
    [
      [0, 1],
      [0, 1],
      [1, 1],
    ],
    // S Shape
    [
      [0, 1, 1],
      [1, 1, 0],
    ],
    // Z Shape
    [
      [1, 1, 0],
      [0, 1, 1],
    ],
  ];

  const [grid, setGrid] = useState(
    Array.from({ length: gridHeight }, () => Array(gridWidth).fill(0))
  );
  const [currentShape, setCurrentShape] = useState(getRandomShape());
  const [currentIcon, setCurrentIcon] = useState(getRandomTechIcon());
  const [position, setPosition] = useState(initialPosition);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);

  function getRandomShape() {
    return shapes[Math.floor(Math.random() * shapes.length)];
  }

  function getRandomTechIcon() {
    return techIcons[Math.floor(Math.random() * techIcons.length)];
  }

  const mergeShape = (gridCopy, shape, pos) => {
    shape.forEach((row, y) => {
      row.forEach((cell, x) => {
        if (
          cell &&
          pos.y + y >= 0 &&
          pos.y + y < gridHeight &&
          pos.x + x >= 0 &&
          pos.x + x < gridWidth
        ) {
          gridCopy[pos.y + y][pos.x + x] = currentIcon;
        }
      });
    });
  };

  const isValidMove = (shape, pos) => {
    for (let y = 0; y < shape.length; y++) {
      for (let x = 0; x < shape[y].length; x++) {
        if (shape[y][x]) {
          let newX = pos.x + x;
          let newY = pos.y + y;
          if (
            newX < 0 ||
            newX >= gridWidth ||
            newY >= gridHeight ||
            (newY >= 0 && grid[newY][newX])
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const moveShape = (dx, dy) => {
    const newPos = { x: position.x + dx, y: position.y + dy };
    if (isValidMove(currentShape, newPos)) {
      setPosition(newPos);
    } else if (dy > 0) {
      // Lock the shape in place
      const gridCopy = grid.map((row) => [...row]);
      mergeShape(gridCopy, currentShape, position);
      clearLines(gridCopy);
      setGrid(gridCopy);
      // Reset to new shape and icon
      const newShape = getRandomShape();
      const newIcon = getRandomTechIcon();
      const newInitialPos = { x: Math.floor(gridWidth / 2) - 1, y: -1 };
      if (!isValidMove(newShape, newInitialPos)) {
        setIsGameOver(true);
        saveHighScore();
      } else {
        setCurrentShape(newShape);
        setCurrentIcon(newIcon);
        setPosition(newInitialPos);
      }
    }
  };

  const rotateShape = () => {
    const rotatedShape = currentShape[0].map((_, index) =>
      currentShape.map((row) => row[index]).reverse()
    );
    if (isValidMove(rotatedShape, position)) {
      setCurrentShape(rotatedShape);
    }
  };

  const clearLines = (gridCopy) => {
    let linesCleared = 0;
    for (let y = gridHeight - 1; y >= 0; y--) {
      if (gridCopy[y].every((cell) => cell)) {
        gridCopy.splice(y, 1);
        gridCopy.unshift(Array(gridWidth).fill(0));
        linesCleared++;
        y++;
      }
    }
    if (linesCleared > 0) {
      setScore((prevScore) => prevScore + linesCleared * 10);
    }
  };

  const handleKeyDown = (e) => {
    if (isGameOver) return;
    e.preventDefault(); // Prevent default scrolling
    switch (e.key) {
      case 'ArrowLeft':
      case 'a':
        moveShape(-1, 0);
        break;
      case 'ArrowRight':
      case 'd':
        moveShape(1, 0);
        break;
      case 'ArrowDown':
      case 's':
        moveShape(0, 1);
        break;
      case 'ArrowUp':
      case 'w':
        rotateShape();
        break;
      default:
        break;
    }
  };

  const saveHighScore = async () => {
    if (score > highScore) {
      const username = prompt(
        'New High Score! Enter your name (8 characters max):',
        'Guest'
      );
      if (username && username.length <= 8) {
        const newHighScore = { name: username, score };
        await set(ref(database, firebasePath), newHighScore);
        setHighScore(score);
      }
    }
  };

  const fetchHighScore = () => {
    const highScoreRef = ref(database, firebasePath);
    onValue(highScoreRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setHighScore(data.score);
      }
    });
  };

  const restartGame = () => {
    setGrid(
      Array.from({ length: gridHeight }, () => Array(gridWidth).fill(0))
    );
    setCurrentShape(getRandomShape());
    setCurrentIcon(getRandomTechIcon());
    setPosition(initialPosition);
    setScore(0);
    setIsGameOver(false);
  };

  useEffect(() => {
    if (isGameOver) return;

    const handleKey = (e) => handleKeyDown(e);
    document.addEventListener('keydown', handleKey);

    const interval = setInterval(() => {
      moveShape(0, 1);
    }, 500);

    return () => {
      document.removeEventListener('keydown', handleKey);
      clearInterval(interval);
    };
  }, [currentShape, position, isGameOver]);

  useEffect(() => {
    fetchHighScore();
  }, []);

  return (
    <div className="block-stacker-container">
      <h2>BlockStacker Game</h2>
      <h6>Stack the technologies I know!</h6>
      <div className="score-board">
        <p>Score: {score}</p>
        <p>High Score: {highScore}</p>
        {isGameOver && <p className="game-over">Game Over!</p>}
      </div>
      <div className="game-area">
        {grid.map((row, rowIndex) => (
          <div key={rowIndex} className="grid-row">
            {row.map((cell, colIndex) => {
              let content = null;
              let isActive = false;
              currentShape.forEach((shapeRow, sy) => {
                shapeRow.forEach((shapeCell, sx) => {
                  if (
                    shapeCell &&
                    rowIndex === position.y + sy &&
                    colIndex === position.x + sx
                  ) {
                    isActive = true;
                    content = currentIcon;
                  }
                });
              });
              if (cell) {
                content = cell;
              }
              return (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className={`cell ${cell || isActive ? 'filled' : ''}`}
                >
                  {content}
                </div>
              );
            })}
          </div>
        ))}
      <div className="controls">
        <p>
          Use Arrow keys, WASD, or the on-screen buttons to move and rotate the
          blocks
        </p>
        <div className="on-screen-controls">
              <button onClick={rotateShape} className="control-btn">
                Rotate
              </button>
        <button onClick={() => moveShape(-1, 0)} className="control-btn">
          Left
        </button>
        <button onClick={() => moveShape(1, 0)} className="control-btn">
          Right
        </button>
        <button onClick={() => moveShape(0, 1)} className="control-btn">
          Down
        </button>
        </div>
      </div>
      </div>
        <button onClick={restartGame} className="restart-btn">
          Restart
        </button>
      <p>Return to <Link to="/">Home</Link></p>
    </div>
  );
};

export default BlockStacker;
